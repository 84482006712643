import {Component, Input, OnInit} from '@angular/core';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {SideNavComponent} from './side-nav.component';
import {CommonModule} from '@angular/common';
import {AppAuthService} from './common/auth';
import {MatTooltipModule} from '@angular/material/tooltip';
import {Router, RouterModule} from '@angular/router';
import {MatDividerModule} from '@angular/material/divider';
import {SocialAuthService} from '@abacritt/angularx-social-login';
import {Company, CompanySite, GenericSetting} from './model';
import {AppState} from './common/app-state';
import {mergeMap} from 'rxjs/operators';
import {CompanyService} from './common/service/company.service';
import {MatListModule} from '@angular/material/list';
import {InfoTextBoxComponent} from './common/info-text-box.component';
import {ServerVersionService} from './server-version.service';
import {NavigationService} from './common/navigation.service';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {GenericSettingService} from './common/service/generic-setting.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-top-nav[sidenav]',
  imports: [
    CommonModule, RouterModule, MatToolbarModule, MatIconModule, MatButtonModule, MatMenuModule,
    MatTooltipModule, MatDividerModule, MatListModule, MatSlideToggleModule
  ],
  templateUrl: 'top-nav.component.html',
  styleUrls: ['top-nav.component.scss']
})
export class TopNavComponent implements OnInit {
  @Input() sidenav!: SideNavComponent;
  companies!: Company[];
  selection?: { company: Company, site: CompanySite };
  version!: string;
  testEnv?: string;
  currentAction?: string;
  adminAvailable!: GenericSetting;

  constructor(public authService: AppAuthService, public appState: AppState, private router: Router,
              private socialAuthService: SocialAuthService, private navigationService: NavigationService,
              private companyService: CompanyService, serverVersionService: ServerVersionService,
              private genericSettingService: GenericSettingService, private snackBar: MatSnackBar) {
    serverVersionService.subscribe(({client}) => this.version = client);
  }

  get manageLink(): string[] | undefined {
    if (this.selection) {
      return ['company', this.selection.company.id, 'site', this.selection.site.id, 'manage'];
    }
    if (this.companies.length == 1) {
      return ['company', this.companies[0].id, 'site', this.companies[0].sites[0].id, 'manage'];
    }
    if (this.companies.length == 0) {
      return ['company', 'create'];
    }
    return undefined;
  }

  get planLink(): string[] | undefined {
    if (this.selection) {
      return ['company', this.selection.company.id, 'site', this.selection.site.id, 'plan'];
    }
    if (this.companies.length == 1) {
      return ['company', this.companies[0].id, 'site', this.companies[0].sites[0].id, 'plan'];
    }
    return undefined
  }

  logout() {
    this.authService.logout();
    this.socialAuthService.signOut(true).finally(() => {
      this.authService.logout();
      setTimeout(() => window.location.href = '/');
      // TODO redirect to www.supplycanvas.com
    });
  }

  async switchBack() {
    await this.authService.switchBack();
    await this.router.navigate(['/']);
  }

  async ngOnInit() {
    this.appState.companySite$.subscribe((companySite) => this.selection = companySite);
    this.appState.companies$.subscribe((companies) => this.companies = companies);
    this.authService.user$
      .pipe(mergeMap(user => user ? [user] : []))
      .subscribe(async (user) => this.companies = await this.companyService.listAll());
    this.navigationService.currentRoute$.subscribe(route => this.currentAction = route?.action);
    const {hostname} = window.location;
    this.testEnv = hostname === 'app.supplycanvas.com' ? undefined : hostname.split('.')[0];
    this.adminAvailable = await this.genericSettingService.getAdminAvailability();
  }

  async toggleAdminAvailability(event: any) {
    const value = event.checked ? 'online' : 'offline';
    this.adminAvailable.value = value;
    await this.genericSettingService.update(this.adminAvailable.id, this.adminAvailable);
    this.snackBar.open(`Admin availability set to ${value}`, 'Close', {duration: 3000});
  }

  openHelp = () => InfoTextBoxComponent.openHelp("https://wiki.supplycanvas.com");

  switchCompany = (company: Company, site: CompanySite) =>
    this.currentAction
      ? ['/company', company.id, 'site', site.id, this.currentAction]
      : ['/company', company.id, 'site', site.id];
}
