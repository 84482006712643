<mat-toolbar>
  <!--
  <button mat-icon-button matTooltip="Menu" (click)="sidenav.toggle()">
    <mat-icon>menu</mat-icon>
  </button>
  -->
  <a (click)="sidenav.toggle()" class="logo"><span class="env" *ngIf="testEnv">{{ testEnv }}</span></a>
  <!--
  <a *canary [routerLink]="['/gpt-test']" mat-flat-button>GPT-Test</a>
  <a *canary [routerLink]="['/dashboard-test']" mat-flat-button>Dashboard-Test</a>
  -->
  <span class="toolbar-item-spacer"></span>

  <ng-container *ngIf="authService.isLoggedIn && companies">
    <ng-container *ngIf="selection?.company && selection?.site; else noCompanySelected">
      <div *ngIf="companies.length" [matMenuTriggerFor]="companyMenu " class="menu company-selector">
        {{ selection?.company?.name }} - {{ selection?.site?.name }}
      </div>

    </ng-container>
    <ng-template #noCompanySelected>
      <button mat-stroked-button [matMenuTriggerFor]="companyMenu ">
        select company/site
        <mat-icon>
          arrow_drop_down
        </mat-icon>
      </button>
    </ng-template>

    <mat-menu #companyMenu yPosition="below">
      <ng-container *ngFor="let company of companies">
        <button *ngFor="let site of company.sites" [routerLink]="switchCompany(company, site)" mat-menu-item>
          {{ company.name }} - {{ site.name }}
        </button>
      </ng-container>
    </mat-menu>

    <button (click)="openHelp()" *ngIf="authService.isLoggedIn" mat-flat-button color="accent">
      <mat-icon>help</mat-icon>
      Help-Center
    </button>

    <button mat-icon-button [matMenuTriggerFor]="userAccountMenu" matTooltip="Account" color="primary">
      <mat-icon>account_circle</mat-icon>
    </button>

    <mat-menu #userAccountMenu yPosition="below">
      <button [routerLink]="['user']" mat-menu-item>
        <mat-icon>person</mat-icon>
        Account
        <div class="username">{{ authService.user?.email }}</div>
      </button>
      <button [routerLink]="['team']" mat-menu-item>
        <mat-icon>groups</mat-icon>
        Your Team
      </button>
      <mat-divider></mat-divider>
      <button [routerLink]="link" *ngIf="manageLink as link" mat-menu-item routerLinkActive="activated">
        <mat-icon>apartment</mat-icon>
        Manage Company
      </button>
      <button [routerLink]="link" *ngIf="planLink as link" mat-menu-item routerLinkActive="activated">
        <mat-icon>receipt</mat-icon>
        Plan &amp; Billing
      </button>
      <mat-divider></mat-divider>
      <button (click)="logout()" mat-menu-item>
        <mat-icon>logout</mat-icon>
        Logout
        <span class="version">Version: {{ version }}</span>
      </button>

      <button (click)="switchBack()" *ngIf="authService.isImpersonated" mat-menu-item style="background-color: #fdd;">
        <mat-icon>sync_alt</mat-icon>
        switch back to
        <div class="username">{{ authService.user?.impersonated }}</div>
      </button>

      <div *ngIf="authService.isAdmin" class="admin">
        <mat-divider></mat-divider>
        <div [disabled]="true" mat-menu-item>Administration:</div>
        <mat-divider></mat-divider>
        <button [routerLink]="['/admin/news-article']" mat-menu-item>News</button>
        <mat-divider></mat-divider>
        <button [routerLink]="['/admin/company']" mat-menu-item>Companies</button>
        <button [routerLink]="['/admin/user']" mat-menu-item>Users</button>
        <mat-divider></mat-divider>
        <button [routerLink]="['/admin/legal-norm']" mat-menu-item>Legal-Norms</button>
        <button [routerLink]="['/admin/location']" mat-menu-item>Locations/GeoShapes</button>
        <button [routerLink]="['/admin/document-type']" mat-menu-item>Document-Types</button>
        <button [routerLink]="['/admin/risk-category']" mat-menu-item>Risk-Categories</button>
        <button [routerLink]="['/admin/site-type']" mat-menu-item>Site-Types</button>
        <button [routerLink]="['/admin/news-source']" mat-menu-item>News-Sources</button>
        <button [routerLink]="['/admin/generic-setting']" mat-menu-item>Generic-Settings</button>
        <button [routerLink]="['/admin/email-template']" mat-menu-item>Email-Templates</button>
        <mat-divider></mat-divider>
        <div class="admin-availability-toggle"
             [ngClass]="{'online': adminAvailable.value==='online', 'offline': adminAvailable.value!=='online'}"
             (click)="$event.stopPropagation()">
          <span>Admin Availability:</span>
          <mat-slide-toggle
            [checked]="adminAvailable.value === 'online'"
            (change)="toggleAdminAvailability($event)"
            color="primary">
            {{ adminAvailable.value }}
          </mat-slide-toggle>
        </div>
        <mat-divider></mat-divider>
        <button [routerLink]="['/admin/tools']" mat-menu-item>DB-Tools</button>
      </div>
    </mat-menu>
  </ng-container>
</mat-toolbar>
