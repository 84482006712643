import {Component, inject, OnInit} from '@angular/core';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {AppAuthService} from './app-auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormActionsComponent} from '../form-actions.component';
import {CommonModule} from '@angular/common';
import {MatDividerModule} from '@angular/material/divider';
import {MatSnackBarModule} from '@angular/material/snack-bar';

@Component({
  selector: 'app-waiting',
  templateUrl: 'waiting.component.html',
  styleUrls: ['login-reset-register.component.scss'],
  imports: [MatCardModule, MatButtonModule, MatIconModule, FormActionsComponent, CommonModule, MatDividerModule, MatSnackBarModule],
  standalone: true
})
export class WaitingComponent implements OnInit {
  private readonly authService = inject(AppAuthService);
  private readonly router = inject(Router);
  private readonly route = inject(ActivatedRoute);
  adminAvailable!: boolean;

  async ngOnInit() {
    this.authService.user$.subscribe(async user => {
      if (!user?.disabled) {
        await this.router.navigate(['/']);
      }
    });
    this.route.data.subscribe(({adminAvailable}) => this.adminAvailable = adminAvailable)
  }

  schedulaCall() {
    const user = this.authService.user;
    if (!user) {
      return;
    }
    window.location.href = `https://meetings-eu1.hubspot.com/gunther-hentschel?email=${encodeURIComponent(user.email)}`;
  }

  startTeamsCall() {
    window.open('https://teams.live.com/l/invite/FEAd9P32sLTYjY7LgI', '_blank');
  }
}
