<mat-sidenav-container class="app-container">
  <mat-sidenav #sidenav [class.mat-elevation-z4]="true">
    <mat-nav-list *ngIf="company && site; else noCompany">
      <mat-list-item [routerLink]="['/company', company.id, 'site', site.id]">
        Dashboard
      </mat-list-item>
      <mat-list-item [routerLink]="['/company', company.id, 'site', site.id, 'chain']" routerLinkActive="activated">
        <div class="circle-number">1</div>
        Supply Chain Builder
      </mat-list-item>
      <mat-list-item [routerLink]="['/company', company.id, 'site', site.id, 'evaluation']"
                     routerLinkActive="activated">
        <div class="circle-number">2</div>
        Draft Reviews
      </mat-list-item>
      <mat-list-item [routerLink]="['/company', company.id, 'site', site.id, 'archive']" routerLinkActive="activated">
        <div class="circle-number">3</div>
        Report Archive
      </mat-list-item>
    </mat-nav-list>

    <ng-template #noCompany>
      <mat-nav-list>
        <a class="back">Main</a>
        <mat-list-item [routerLink]="['/']" routerLinkActive="activated">
          Dashboard
        </mat-list-item>
      </mat-nav-list>
    </ng-template>
  </mat-sidenav>

  <mat-sidenav-content>
    <main [class.wide]="wide" [class.extra-wide]="extraWide">
      <ng-content></ng-content>
    </main>
  </mat-sidenav-content>

</mat-sidenav-container>
