import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {AppAuthService} from './app-auth.service';
import {map} from 'rxjs/operators';

export const authenticatedGuard = (_: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const router = inject(Router);
  const authService = inject(AppAuthService);
  return authService.user$.pipe(
    map(async user => {
      const {url} = state;
      if (user == null) {
        const returnUrl = url === '/' ? undefined : url;
        await router.navigate(['/login'], {queryParams: {returnUrl}});
        return false;
      }
      if (user.disabled && !(url.startsWith('/waiting') || url.startsWith('/invite') || url.startsWith('/user') ||
        url.startsWith('/team'))) {
        await router.navigate(['/waiting']);
        return false;
      }
      return true;
    })
  );
}

