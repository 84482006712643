import {HttpClient} from '@angular/common/http';
import {firstValueFrom, map} from 'rxjs';
import {GoogleLoginProvider} from '@abacritt/angularx-social-login';

interface AuthProviderConfig {
  googleClientId: string
  microsoftClientId: string
}

export const loadSocialAuthConfig = (http: HttpClient) => firstValueFrom(
  http.get<AuthProviderConfig>('api/auth/provider').pipe(
    map(({googleClientId}) => ({
        autoLogin: false,
        providers: [
          {
            id: 'GOOGLE',
            provider: new GoogleLoginProvider(googleClientId,
              {
                oneTapEnabled: false
              })
          },
        ],
        onError: (err: any) => console.error(err)
      })
    )
  ))
