<mat-card>
  <mat-card-header>
    <h1 mat-card-title>Thank you for registering with supplycanvas!</h1>
  </mat-card-header>

  <mat-card-content>

    <div>
      We're excited to have you! Schedule a quick video call to unlock your full account and ensure supplycanvas is
      tailored to your goals.<br/>
      This call allows us to better understand your goals and ensure supplycanvas is perfectly set
      up to meet your needs.
    </div>

    <div>
      Scheduling is easy - simply choose a time that works best for you. After the call, you will receive an activation
      link via email, giving you full access to your account!
    </div>

    <form-actions>
      <button (click)="schedulaCall()" color="primary" mat-raised-button>
        <mat-icon>schedule</mat-icon>
        pick a time
      </button>
    </form-actions>

    <div *ngIf="adminAvailable" class="contact-now-section">
      <h3>Need immediate assistance?</h3>
      <p>Our team is currently available for a quick call. Connect with us directly:</p>
      <div class="contact-buttons">
        <button (click)="startTeamsCall()" color="primary" mat-raised-button>
          <mat-icon>people</mat-icon>
          Microsoft Teams
        </button>
        gunther.hetnschel&#64;supplycanvas.com
      </div>
      <div class="contact-buttons">
        <a href="tel:+49351486706333" color="primary" mat-raised-button>
          <mat-icon>phone</mat-icon>
          Start Phone Call
        </a>
        +49 351 486706333
      </div>
    </div>
  </mat-card-content>
</mat-card>
